/*
 * 업무구분: 리크루팅 동의
 * 화면 명: MSPRC100M
 * 화면 설명: 리크루팅동의 동의
 * 화면 접근권한: 일반사용자, 지점장, 지역단스텝, 리크루팅본사스텝 (U000, F110, F102, R100)
 */
<template>
  <ur-page-container title="리크루팅동의" :show-title="true" type="subpage" action-type="none">
    <ur-box-container direction="column" align-v="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-agreeArea2">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column">
          <div class="agree-box">
            <span class="agree-txt">
              귀하는 개인(신용)정보 수집·이용, 제공, 조회에 대한 동의를 거부할 수 있습니다. 다만, 
              <strong>본 동의를 거부하시는 경우에는 "당사 보험설계사 후보자로의 등록 및 협회 자격 대비 과정 참여" 등이 불가함</strong> 
              을 알려드립니다. 본 동의서에 의한 개인(신용)정보 조회는 귀하의 신용 등급에 영향을 주지 않습니다.
            </span>
          </div>
          <div class="chk-box">
            <div class="chk-title">
              후보자 관리 및 위촉 가능여부 판단을 위한 개인(신용)정보 처리 동의서
            </div>
            <div class="ns-check">
              <mo-checkbox v-model="isAllAgree" @input="fn_selectAgreeAll">전체동의</mo-checkbox>
            </div>
          </div>
          <mo-list-item expandable class="agree-list" ref="item1" preventScrollWhenExpand>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <div class="ns-check">
                  <mo-checkbox v-model="agreeCheckboxList" value="1" @input="fn_Check1">1. 수집 · 이용에 관한 사항</mo-checkbox>
                </div>
              </div>
            </div>
            <template v-slot:expand>
              <div class="list-txtBox">
                <p class="pb30">
                  당사 및 당사 업무수탁자가 「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한 법률」에 따라 아래와 같이 귀하의 개인(신용)정보를 수집·이용 합니다.
                </p>
                <strong>■ 수집 · 이용 목적</strong>
                <ul class="txt-indent">
                  <li>- 컨설턴트 후보자 관리 시스템 등록 및 배양</li>
                  <li>- 설계사 직무 소개(JOB 세미나 정보제공, 리크루팅을 위한 안내자료 제공)</li>
                  <li>- 보험설계사 적격 여부 판단</li>
                  <li>- 설문조사(세일즈 적성검사)를 통한 세일즈 성향 및 우수 역량 파악</li>
                  <li>- 협회자격(모집인등록시험, 등록필수교육) 대비과정 운영</li>
                </ul>
              </div>
              <div class="list-txtBox">
                <strong>■ 보유 · 이용기간</strong>
                <p class="txt-underline">수집·이용 동의일로부터 1년</p>
              </div>
              <div class="list-txtBox">
                <span class="title">수집·이용 항목</span>
                <strong>■ 고유식별정보(2개)</strong>
                <p class="txt-blue">
                  주민등록번호, 외국인등록번호
                </p>
                <div class="ns-check">
                  <p>위 <span class="txt-underline">고유식별정보 수집·이용</span>에 동의하십니까?</p>
                  <mo-checkbox v-model="agreeSubCheckboxList1_1" @input="fn_agreeSubCheckboxList1_1">동의함</mo-checkbox>
                  <mo-checkbox v-model="dontAgreeSubCheckboxList1_1" @input="fn_dontAgreeSubCheckboxList1_1">동의안함</mo-checkbox>
                </div>
              </div>
              <div class="list-txtBox last">
                <strong>■ 개인(신용)정보</strong>
                <p>
                  - 일반개인정보<br>
                  성명, 주소, 전화번호, 이메일, 직업, 직장, I-PIN, Safe-Key, 성별, 생년월일, 가족관계, 유·무선 전화번호, 국적, 경력, 운전여부, 취미
                </p>
                <p class="pt15">
                  -신용거래정보<br>
                  보험설계사 지원 전후의 대출, 채무보증
                </p>
                <p class="pt15">
                  - 신용도판단정보<br>
                  보증보험 사고 유무
                </p>
                <p class="pt15">
                  - 공공정보<br>
                  생명/손해보험협회 등록이력, 등록시험 응시/합격 정보, 등록필수교육 수료 정보, 체납정보, 신용등급
                </p>
                <div class="ns-check">
                  <p>위 <span class="txt-underline">개인신용정보 수집·이용</span>에 동의하십니까?</p>
                  <mo-checkbox v-model="agreeSubCheckboxList1_2" @input="fn_agreeSubCheckboxList1_2">동의함</mo-checkbox>
                  <mo-checkbox v-model="dontAgreeSubCheckboxList1_2" @input="fn_dontAgreeSubCheckboxList1_2">동의안함</mo-checkbox>
                </div> 
              </div>
            </template>
          </mo-list-item>
          <mo-list-item expandable class="agree-list" ref="item2" preventScrollWhenExpand>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <div class="ns-check">
                  <mo-checkbox v-model="agreeCheckboxList" value="2">2. 제공에 관한 사항</mo-checkbox>
                </div>
              </div>
            </div>
            <template v-slot:expand>
              <div class="list-txtBox">
                <strong>■ 제공받는 자</strong>
                <ul class="txt-indent">
                  <li>- 생명/손해보험협회</li>
                  <li>- 은행연합회</li>
                  <li>- 나이스신용평가정보(주)</li>
                  <li>- 서울보증보험</li>
                  <li>- 보험연수원</li>
                  <li>- 모집종사자 교육협의회</li>
                </ul>
              </div>
              <div class="list-txtBox">
                <strong>■ 제공받는 자의 이용 목적</strong>
                <p>
                  - 생명/손해보험협회: 보험설계사 자격 적부 판단<br>
                  - 은행연합회: 보험설계사 자격 적부 판단<br>
                  - 나이스신용평가정보(주): 신용상태 조회<br>
                  - 서울보증보험: 보증보험 가입/청구/사고 여부 확인<br>
                  - 보험연수원: 설계사 등록필수교육 안내/진행/관리<br>
                  - 모집종사자 교육협의회: 설계사 등록필수교육 수료 여부 확인
                </p>
              </div>
              <div class="list-txtBox">
                <strong>■ 보유 및 이용기간</strong>
                <p>
                  <span class="txt-underline">수집·이용 동의일로부터 1년<br></span>
                </p>
              </div>
              <div class="list-txtBox">
                <span class="title">제공 항목</span>
                <strong>■ 고유식별정보</strong>
                <p class="txt-blue">
                  - 주민등록번호, 외국인등록번호<br>
                </p>
                <div class="ns-check">
                  <p>위 <span class="txt-underline">고유식별정보 제공</span>에 동의하십니까?</p>
                  <mo-checkbox v-model="agreeSubCheckboxList2_1" @input="fn_agreeSubCheckboxList2_1">동의함</mo-checkbox>
                  <mo-checkbox v-model="dontAgreeSubCheckboxList2_1" @input="fn_dontAgreeSubCheckboxList2_1">동의안함</mo-checkbox>
                </div>
              </div>
              <div class="list-txtBox last">
                <strong>■ 개인(신용)정보</strong>
                <p>
                  - 일반개인정보<br>
                  성명, 주소, 전화번호, 이메일, 직업, 직장, I-PIN, Safe-Key, 성별, 생년월일, 가족관계, 유·무선 전화번호, 국적, 경력, 운전여부, 취미
                </p>

                <div class="ns-check">
                  <p>위 <span class="txt-underline">개인신용정보 제공</span>에 동의하십니까?</p>
                  <mo-checkbox v-model="agreeSubCheckboxList2_2" @input="fn_agreeSubCheckboxList2_2">동의함</mo-checkbox>
                  <mo-checkbox v-model="dontAgreeSubCheckboxList2_2" @input="fn_dontAgreeSubCheckboxList2_2">동의안함</mo-checkbox>
                </div>
                
                <p class="pt15">
                  당사의 모집위탁계약을 체결한 자(설계사 등), 보험중개사, 리크루팅 활동에 필요한 업무를 위탁받은 자(전산개발·유지·보수회사, 위탁콜센터, 안내자료·택배·기프티콘 발송업체 등)에 업무위탁을 목적으로 귀하의 정보를 제공할 수 있습니다.
                </p>
              </div>
            </template>
          </mo-list-item>
          <mo-list-item expandable class="agree-list" ref="item3" preventScrollWhenExpand>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <div class="ns-check">
                  <mo-checkbox v-model="agreeCheckboxList" value="3">3. 조회에 관한 사항</mo-checkbox>
                </div>
              </div>
            </div>
            <template v-slot:expand>
              <div class="list-txtBox">
                <strong>■ 조회 대상 기관</strong>
                <ul class="txt-indent">
                  <li>- 생명/손해보험협회, 은행연합회, 나이스신용평가정보(주), 서울보증보험, 보험연수원, 모집종사자 교육협의회, 보험회사(체신관서, 공제사업자 포함), 한국인터넷진흥원</li>
                </ul>
              </div>
              <div class="list-txtBox">
                <strong>■ 조회 목적</strong>
                <ul class="txt-indent">
                  <li>- 생명/손해보험협회: 보험설계사 자격 적부 판단</li>
                  <li>- 은행연합회: 보험설계사 자격 적부 판단</li>
                  <li>- 나이스신용평가정보(주): 신용상태 조회</li>
                  <li>- 서울보증보험: 보증보험 가입/청구/사고 여부 확인</li>
                  <li>- 보험연수원: 설계사 등록필수교육 안내/진행/관리</li>
                  <li>- 모집종사자 교육협의회: 설계사 등록필수교육 수료 여부 확인</li>
                  <li>- 보험회사(체신관서, 공제사업자 포함): 보험사의 위촉 계약 여부 확인</li>
                  <li>- 한국인터넷 진흥원: I-PIN 정보</li>
                </ul>
              </div>
              <div class="list-txtBox">
                <strong>■ 조회 동의의 효력기간</strong>
                <p>
                  <span class="txt-underline">수집·이용 동의일로부터 1년</span>
                </p>
              </div>
              <div class="list-txtBox">
                <span class="title">조회 항목</span>
                <strong>■ 고유식별정보(2개)</strong>
                <p class="txt-blue">
                  - 주민등록번호, 외국인등록번호<br>
                </p>
                <div class="ns-check">
                  <p>위 <span class="txt-underline">고유식별정보 조회</span>에 동의하십니까?</p>
                  <mo-checkbox v-model="agreeSubCheckboxList3_1" @input="fn_agreeSubCheckboxList3_1">동의함</mo-checkbox>
                  <mo-checkbox v-model="dontAgreeSubCheckboxList3_1" @input="fn_dontAgreeSubCheckboxList3_1">동의안함</mo-checkbox>
                </div>
              </div>
              <div class="list-txtBox last">
                <strong>■ 개인(신용)정보</strong>
                <p>
                  - 일반개인정보<br>
                  성명, 주소, 전화번호, 이메일, 직업, 직장, I-PIN, Safe-Key
                </p>
                <p class="pt15">
                  - 신용거래정보<br>
                  보험설계사 지원 전후의 대출, 채무보증
                </p>
                <p class="pt15">
                  - 신용도판단정보<br>
                  보증보험 사고 유무
                </p>
                <p class="pt15">
                  - 공공정보<br>
                  생명/손해보험협회 등록이력, 등록시험 응시/합격 정보, 등록필수교육 수료 정보, 체납정보, 신용등급
                </p>
                <div class="ns-check">
                  <p>위 <span>개인신용정보 조회</span>에 동의하십니까?</p>
                  <mo-checkbox v-model="agreeSubCheckboxList3_2" @input="fn_agreeSubCheckboxList3_2">동의함</mo-checkbox>
                  <mo-checkbox v-model="dontAgreeSubCheckboxList3_2" @input="fn_dontAgreeSubCheckboxList3_2">동의안함</mo-checkbox>
                </div> 
                <p class="pt15">
                  본인은 「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한 법률」에 따라 귀사가 본인의 개인(신용)정보를 상기 내용과 같이 처리하는 것에 동의합니다.
                </p>
              </div>
            </template>
          </mo-list-item>
          <div class="ciso-txt">
            <span>[CISO심의필 2021-149]</span>
            필수컨설팅 동의 유효한 고객만 후보자카드가 생성됩니다.<br>
            신규 고객은 필수컨설팅 동의를 먼저 받은 후 리크루팅 동의를 받아주세요.
          </div>
        </ur-box-container>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!isAllAgree || !isProgress"
            @click="fn_NextPage">다음</mo-button>
        </div>
        </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>

export default {
  name: 'MSPRC100M',
  screenId: 'MSPRC100M',
  components: {
  },
  data() {
    return {
      isAllAgree: false,
      dontAgreeCheckbox: false,
      agreeCheckboxList: [],
      dontAgreeCheckboxList: [],
      dontAgreeSubCheckboxList: [],
      agreeSubCheckboxList: [],
      dontAgreeCheckboxList1: false,
      dontAgreeCheckboxList2: false,
      dontAgreeCheckboxList3: false,
      dontAgreeSubCheckboxList1_1: false,
      dontAgreeSubCheckboxList1_2: false,
      dontAgreeSubCheckboxList2_1: false,
      dontAgreeSubCheckboxList2_2: false,
      dontAgreeSubCheckboxList3_1: false,
      dontAgreeSubCheckboxList3_2: false,
      agreeSubCheckboxList1_1: false,
      agreeSubCheckboxList1_2: false,
      agreeSubCheckboxList2_1: false,
      agreeSubCheckboxList2_2: false,
      agreeSubCheckboxList3_1: false,
      agreeSubCheckboxList3_2: false,
      allItems: ['1', '2', '3'],
      userInfo: {},
      isProgress: false,
      isDev: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' )
    }
  },
  monuted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC100M')
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    // 컨설턴트와 지점장만 리크루팅 동의 하도록 제어함
    // 지역단장 중 지점장(F110)과 지역단(F102) 모두 가진 사용자가 존재하여 조건 추가
    let userRoleList = this.userInfo.userRoleList

    if (this.userInfo.isEntpwRolYn !== 'Y' && (this.userInfo.isFc === 'Y' || this.userInfo.isBMngr === 'Y') && !(userRoleList.includes('F102') && userRoleList.includes('F110'))) {
      console.log('isFc, isBMngr')
      this.isProgress = true
    } else {
      console.log('ect isFc, isBMngr')
      this.isProgress = false
      this.getStore('confirm').dispatch('ADD', '리크루팅 동의는 컨설턴트와 지점장만 가능합니다.')
    }

    if (this.isDev) { // 로컬 및 개발계는 그냥 통과
      this.isProgress = true
    }

    let lv_Vm = this
    this.$nextTick(()=> {
      lv_Vm.$refs.item1.isExpand = true
      lv_Vm.$refs.item2.isExpand = true
      lv_Vm.$refs.item3.isExpand = true

      // lv_Vm.$refs.scroll.$el.scrollTop = 0
    })
  },
  watch: {
    isAllAgree (newValue) {
      this.$emit('completed', newValue)
      // this.$emit('agreeCompleted', newValue)
      if (newValue) {
        this.$refs.item1.isExpand = false
        this.$refs.item2.isExpand = false
        this.$refs.item3.isExpand = false
      }
    },
    // completed (newValue) {
    //   console.log('TSSRC210D completed watch ===> ', newValue)
    //   this.$emit('completed', this.tabIndex, newValue)
    // },
    agreeCheckboxList (newValue) {
      if (newValue.length === 0) {
        this.agreeSubCheckboxList1_1 = false
        this.agreeSubCheckboxList1_2 = false
        this.agreeSubCheckboxList2_1 = false
        this.agreeSubCheckboxList2_2 = false
        this.agreeSubCheckboxList3_1 = false
        this.agreeSubCheckboxList3_2 = false
      }
      let check1 = false
      let check2 = false
      let check3 = false
      newValue.forEach((item, idx) => {
        if (item === '1') check1 = true
        else if (item === '2') check2 = true
        else if (item === '3') check3 = true
      })
      if (check1) {
        this.agreeSubCheckboxList1_1 = true
        this.agreeSubCheckboxList1_2 = true
        this.dontAgreeCheckboxList1 = false
        this.dontAgreeSubCheckboxList1_1 = false
        this.dontAgreeSubCheckboxList1_2 = false
        this.$refs.item1.isExpand = false
      } else {
        if (this.agreeSubCheckboxList1_1 && this.agreeSubCheckboxList1_2) {
          this.agreeSubCheckboxList1_1 = false
          this.agreeSubCheckboxList1_2 = false
        }
        this.$refs.item1.isExpand = true
      }
      if (check2) {
        this.agreeSubCheckboxList2_1 = true
        this.agreeSubCheckboxList2_2 = true
        this.dontAgreeCheckboxList2 = false
        this.dontAgreeSubCheckboxList2_1 = false
        this.dontAgreeSubCheckboxList2_2 = false
        this.$refs.item2.isExpand = false
      } else {
        if (this.agreeSubCheckboxList2_1 && this.agreeSubCheckboxList2_2) {
          this.agreeSubCheckboxList2_1 = false
          this.agreeSubCheckboxList2_2 = false
        }
        this.$refs.item2.isExpand = true
      }
      if (check3) {
        this.agreeSubCheckboxList3_1 = true
        this.agreeSubCheckboxList3_2 = true
        this.dontAgreeCheckboxList3 = false
        this.dontAgreeSubCheckboxList3_1 = false
        this.dontAgreeSubCheckboxList3_2 = false
        this.$refs.item3.isExpand = false
      } else {
        if (this.agreeSubCheckboxList3_1 && this.agreeSubCheckboxList3_2) {
          this.agreeSubCheckboxList3_1 = false
          this.agreeSubCheckboxList3_2 = false
        }
        this.$refs.item3.isExpand = true
      }
      if (newValue.length === 3) {
        this.dontAgreeCheckbox = false
        this.isAllAgree = true        
      } else {
        this.isAllAgree = false
      }
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_Check1 () {
      
    },
    fn_selectAgreeAll () {
      let lv_Vm = this
      lv_Vm.allItems = ['1', '2', '3']
      lv_Vm.agreeCheckboxList = lv_Vm.isAllAgree ? lv_Vm.allItems : []
    },
    fn_detailCheck (value) {
      let lv_Vm = this
      lv_Vm.agreeCheckboxList.forEach((item, idx) => {
        if (item === value) {
          // console.log('fn_detailCheck ==> ', item, value)
          lv_Vm.agreeCheckboxList.splice(idx, 1)
        }
      })
    },
    fn_dontAgreeSubCheckboxList1_1 () {
      this.fn_dontAgreeSubCheckboxList('1_1')
    },
    fn_dontAgreeSubCheckboxList1_2 () {
      this.fn_dontAgreeSubCheckboxList('1_2')
    },
    fn_dontAgreeSubCheckboxList2_1 () {
      this.fn_dontAgreeSubCheckboxList('2_1')
    },
    fn_dontAgreeSubCheckboxList2_2 () {
      this.fn_dontAgreeSubCheckboxList('2_2')
    },
    fn_dontAgreeSubCheckboxList3_1 () {
      this.fn_dontAgreeSubCheckboxList('3_1')
    },
    fn_dontAgreeSubCheckboxList3_2 () {
      this.fn_dontAgreeSubCheckboxList('3_2')
    },
    fn_dontAgreeSubCheckboxList (value) {
      // console.log('fn_dontAgreeSubCheckboxList', value)
      let type = value.substr(0, 1)
      let checked1 = this[`dontAgreeSubCheckboxList${type}_1`]
      let checked2 = this[`dontAgreeSubCheckboxList${type}_2`]
      if (checked1 && checked2) {
        this[`dontAgreeCheckboxList${type}`] = true
      } else {
        this[`dontAgreeCheckboxList${type}`] = false
      }
      if (this[`dontAgreeSubCheckboxList${value}`]) {
        this[`agreeSubCheckboxList${value}`] = false
        this.fn_detailCheck(type)
      }
    },
    fn_agreeSubCheckboxList1_1 () {
      this.fn_agreeSubCheckboxList('1_1')
    },
    fn_agreeSubCheckboxList1_2 () {
      this.fn_agreeSubCheckboxList('1_2')
    },
    fn_agreeSubCheckboxList2_1 () {
      this.fn_agreeSubCheckboxList('2_1')
    },
    fn_agreeSubCheckboxList2_2 () {
      this.fn_agreeSubCheckboxList('2_2')
    },
    fn_agreeSubCheckboxList3_1 () {
      this.fn_agreeSubCheckboxList('3_1')
    },
    fn_agreeSubCheckboxList3_2 () {
      this.fn_agreeSubCheckboxList('3_2')
    },
    fn_agreeSubCheckboxList (value) {
      let type = value.substr(0, 1)
      let checked1 = this[`agreeSubCheckboxList${type}_1`]
      let checked2 = this[`agreeSubCheckboxList${type}_2`]

      this[`dontAgreeSubCheckboxList${value}`] = false
      if (checked1 && checked2) {
        this.agreeCheckboxList.push(type)
      } else {
        this.fn_detailCheck(type)
      }
    },
    fn_NextPage () {
      this.$router.push( { name: 'MSPRC110M' } )
    }
  }
}
</script>
